import React, { useState } from 'react';
import './CustomCarousel.css'; // Assuming you will put the SCSS content in a CSS file

const CustomCarousel = ({ images }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility
  const [selectedImage, setSelectedImage] = useState(null); // State to store the clicked image

  // Function to open the modal and set the clicked image
  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <section>
      <div className="container">
        <div className="carousel">
          {images.map((image, index) => (
            <input
              type="radio"
              name="slides"
              id={`slide-${index + 1}`}
              key={index}
              defaultChecked={index === 0}
            />
          ))}
          <ul className="carousel__slides">
            {images.map((image, index) => (
              <li className="carousel__slide" key={index}>
                <figure>
                  <div>
                    <img 
                      src={image.src} 
                      alt={`Slide ${index + 1}`} 
                      onClick={() => openModal(image.src)} // Click event to open the modal only for the main image
                    />
                  </div>
                  <figcaption>
                    {image.description}
                  </figcaption>
                </figure>
              </li>
            ))}
          </ul>
          <ul className="carousel__thumbnails">
            {images.map((image, index) => (
              <li key={index}>
                <label htmlFor={`slide-${index + 1}`}>
                  <img 
                    src={image.thumbnail} 
                    alt={`Thumbnail ${index + 1}`} 
                    /* No onClick on the thumbnails, it only applies to the main image */
                  />
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Modal for displaying the image in larger view */}
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <span className="modal__close" onClick={closeModal}>&times;</span>
          <img className="modal__content" src={selectedImage} alt="Large view" />
        </div>
      )}
    </section>
  );
};

export default CustomCarousel;
