import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Services.css';

const services = [
  {
    title: 'Plomberie/Chauffage',
    description: 'Installation, réparation, et entretien des systèmes de plomberie et chauffage.',
    image: require(`../images/main_plomberie.png`),
    tag: 'Adrenaline',
    link: '/services/plomberie'
  },
  {
    title: 'Électricité',
    description: 'Services électriques de dépannage de neuf ou de rénovation.',
    image: require(`../images/main_electricite.png`),
    tag: 'Electricity',
    link: '/services/electricite'
  },
  {
    title: 'Débouchages canalisations',
    description: 'Débouchages rapides et efficaces, inspections vidéo.',
    image: require(`../images/main_canalisation.png`),
    tag: 'Plumbing',
    link: '/services/debouchagecanalisation'
  },
  {
    title: 'Multi-services',
    description: 'Petits bricolages, entretien, jardinage, et plus.',
    image: require(`../images/main_multiservices.png`),
    tag: 'Multi-services',
    link: '/services/multi-services'
  },
];

const Services = () => {
  const navigate = useNavigate();

  const handleDevisClick = () => {
    navigate('/demander-un-devis');
  };

  return (
    <section className="services">
      <button className="cta-button" onClick={handleDevisClick}>Demander un devis gratuitement</button>
      <h2>Nos Services</h2>
      <div className="service-boxes">
        {services.map((service, index) => (
          <div 
            key={index} 
            className="service-box" 
            onClick={() => navigate(service.link)} // Make the entire box clickable
            style={{ cursor: 'pointer' }} // Ensure the pointer cursor shows up
          >
            <img src={service.image} alt={service.title} className="service-image" />
            <div className="service-content">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;