import React, { useEffect } from 'react';

const MapSection = () => {
  useEffect(() => {
    // Liste des villes et leurs coordonnées
    const villes = [
      { name: "Saint-Gilles-Croix-de-Vie", lat: 46.6967, lng: -1.9372 },
      { name: "Saint-Jean-de-Monts", lat: 46.7935, lng: -2.0716 },
      { name: "Challans", lat: 46.8470, lng: -1.8784 },
      { name: "La Roche-sur-Yon", lat: 46.6705, lng: -1.4260 },
      { name: "Talmont-Saint-Hilaire", lat: 46.4651, lng: -1.6107 },
      { name: "Saint-Hilaire-de-Riez", lat: 46.7226, lng: -1.9681 },
    ];

    // Calculer la latitude et la longitude moyennes pour le centre
    const avgLat =
      villes.reduce((sum, ville) => sum + ville.lat, 0) / villes.length;
    const avgLng =
      villes.reduce((sum, ville) => sum + ville.lng, 0) / villes.length;

    // Ajuster le centre pour éviter de couvrir trop de mer
    const adjustedCenter = { lat: avgLat - 0.05, lng: avgLng + 0.1 };

    // Déterminer le rayon minimum pour couvrir toutes les villes
    let maxDistance = 0;
    villes.forEach((ville) => {
      const distance = haversineDistance(
        adjustedCenter,
        { lat: ville.lat, lng: ville.lng }
      );
      if (distance > maxDistance) {
        maxDistance = distance;
      }
    });

    // Ajuster le rayon avec une marge
    const adjustedRadius = maxDistance * 1000 * 1.05; // Rayon en mètres avec une marge de 5%

    // Initialiser la carte
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: adjustedCenter, // Centré sur le centre ajusté
      zoom: 9,
    });

    // Ajouter un cercle global
    const serviceAreaCircle = new window.google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.2,
      map: map,
      center: adjustedCenter,
      radius: adjustedRadius, // Rayon en mètres
    });

    // Ajouter des marqueurs pour chaque ville
    villes.forEach((ville) => {
      const marker = new window.google.maps.Marker({
        position: { lat: ville.lat, lng: ville.lng },
        map: map,
        title: ville.name,
      });

      // Ajouter une fenêtre d'information sur chaque marqueur
      const infoWindow = new window.google.maps.InfoWindow({
        content: `<h4>${ville.name}</h4>`,
      });

      marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });
    });

    // Fonction pour calculer la distance entre deux points (formule de Haversine)
    function haversineDistance(coords1, coords2) {
      const toRad = (x) => (x * Math.PI) / 180;
      const R = 6371; // Rayon de la Terre en km
      const dLat = toRad(coords2.lat - coords1.lat);
      const dLng = toRad(coords2.lng - coords1.lng);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(coords1.lat)) *
          Math.cos(toRad(coords2.lat)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance; // Distance en km
    }
  }, []);

  return (
    <div className="map-section">
      <div className="titre">
        <h2>Notre secteur d'activité</h2>
      </div>
      <div id="map" style={{ width: '100%', height: '450px' }}></div>
    </div>
  );
};

export default MapSection;
