import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation(); // Obtenir le chemin de la route

  useEffect(() => {
    // Faire défiler la page en haut à chaque changement de route
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // Ce composant n'affiche rien à l'écran
};

export default ScrollToTop;
