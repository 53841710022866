import React, { useState } from 'react';
import './StarRating.css';

const StarRating = ({ setNote }) => {
  const [hoveredRating, setHoveredRating] = useState(0); // Note survolée
  const [selectedRating, setSelectedRating] = useState(0); // Note sélectionnée

  const handleMouseEnter = (rating) => {
    setHoveredRating(rating); // Met à jour la note survolée
  };

  const handleMouseLeave = () => {
    setHoveredRating(0); // Réinitialise lorsqu'on ne survole plus
  };

  const handleClick = (rating) => {
    setSelectedRating(rating); // Fixe la note sélectionnée
    setNote(rating); // Met à jour la note dans le formulaire parent
  };

  return (
    <div className="star-ratingg">
      {Array.from({ length: 5 }, (_, index) => (
        <span
          key={index}
          className={`star ${index < (hoveredRating || selectedRating) ? 'filled' : ''}`}
          onMouseEnter={() => handleMouseEnter(index + 1)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(index + 1)}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};

export default StarRating;
