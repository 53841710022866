import React, { useState, useEffect } from 'react';
import './Avis.css';
import StarRating from './StarRating'; // Importez le composant des étoiles

const Avis = () => {
  const [avis, setAvis] = useState([]);
  const [showForm, setShowForm] = useState(false); // Pour afficher ou masquer le formulaire
  const [name, setName] = useState('');
  const [numeroDevis, setNumeroDevis] = useState('');
  const [note, setNote] = useState(0); // Initialiser avec 0 pour les étoiles
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetch('https://deshaienergies.fr/getAvis.php')
      .then(response => response.json())
      .then(data => setAvis(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleSubmit = () => {
    // Reset error and success messages
    setError('');
    setSuccess('');

    // Valider si le numéro de devis existe
    fetch('https://deshaienergies.fr/verifyDevis.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, numeroDevis }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Si le devis est trouvé, envoyer l'avis
          fetch('https://deshaienergies.fr/addAvis.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, numeroDevis, note, message }),
          })
            .then(response => response.json())  // Assurez-vous de parser le JSON ici
            .then(result => {
              if (result.status === 'success') { // Vérifiez la clé 'status' de l'objet JSON
                setSuccess(result.message); // Vous pouvez utiliser le message retourné
                // Actualiser la liste des avis après l'ajout
                fetch('https://deshaienergies.fr/getAvis.php')
                  .then(response => response.json())
                  .then(data => setAvis(data));
              } else {
                setError(result.message || 'Une erreur est survenue lors de l\'ajout de l\'avis.');
              }
            });
        } else {
          setError('Aucun devis ne correspond à ce nom.');
        }
      })
      .catch(() => {
        setError('Une erreur est survenue lors de la vérification du devis.');
      });
  };

  return (
    <div className="avis-page">
      <h2>Avis des clients</h2>
      <button className="styled-button" onClick={() => setShowForm(!showForm)}>
        Ajouter un avis
      </button>
      {showForm && (
        <div className="add-avis-form">
          <h3>Déposer un avis</h3>
          <div className="input-group">
            <label>Nom</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Votre nom"
            />
          </div>
          <div className="input-group">
            <label>Numéro de devis</label>
            <input
              type="text"
              value={numeroDevis}
              onChange={(e) => setNumeroDevis(e.target.value)}
              placeholder="Numéro de devis"
            />
          </div>
          <div className="input-group">
            <label>Note</label>
            <StarRating setNote={setNote} /> {/* Utilisation du composant d'étoiles */}
          </div>
          <div className="input-group">
            <label>Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Votre avis"
            />
          </div>
          <button onClick={handleSubmit}>Envoyer l'avis</button>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
        </div>
      )}
      {avis.length === 0 ? (
        <p>Aucun avis disponible pour le moment.</p>
      ) : (
        avis.map((avis, index) => (
          <div key={index} className="avis">
            <h3>{avis.nom} - {avis.note}/5</h3>
            <p dangerouslySetInnerHTML={{ __html: avis.message }}></p> {/* Encodage sécurisé des caractères spéciaux */}
            <small>{new Date(avis.date).toLocaleDateString()}</small>
          </div>
        ))
      )}
    </div>
  );
};

export default Avis;
