import React, { useEffect, useState } from 'react';
import './Header.css';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../images/pnglogo.png'; // Correct the path based on your project structure

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);

  useEffect(() => {
    // Fonction pour gérer le scroll
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Ajouter et retirer l'événement de scroll
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Fetch des avis pour calculer la moyenne des notes et le nombre d'avis
    fetch('https://deshaienergies.fr/getAvis.php')
      .then((response) => response.json())
      .then((data) => {
        const totalReviews = data.length;
        const totalRating = data.reduce((sum, review) => sum + parseInt(review.note), 0);
        const average = totalReviews > 0 ? (totalRating / totalReviews).toFixed(1) : 0;
        setAverageRating(average);
        setReviewCount(totalReviews);
      })
      .catch((error) => console.error('Erreur lors du fetch des avis :', error));
  }, []);

  return (
    <header className={`header ${scrolled ? 'scrolled' : ''}`}>
      <nav className="nav">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>

        {location.pathname !== '/' && (
          <div className="menu-button-container">
            <button className="menu-button" onClick={() => navigate('/')}>
              Menu Principal
            </button>
          </div>
        )}

        {location.pathname === '/' && !scrolled && (
          <div className="star-rating" onClick={() => navigate('/avis')}>
            <span className="stars" title={`${averageRating}/5 (${reviewCount} avis)`}>
              {Array.from({ length: 5 }, (_, i) => (
                <span key={i} className={i < averageRating ? 'filled' : ''}>
                  &#9733;
                </span>
              ))}
            </span>
            <p>{averageRating}/5 ({reviewCount} avis)</p>
          </div>
        )}

        <div className={`contact-info ${scrolled ? 'horizontal' : ''}`}>
          <p>+33 6 12 63 87 86</p>
        </div>
      </nav>
    </header>
  );
};

export default Header;
