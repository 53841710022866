import React, { useState } from 'react';
import LocationStep from './LocationStep';
import WorkTypeStep from './WorkTypeStep';
import ContactInfoStep from './ContactInfoStep';
import './DevisForm.css';

const DevisForm = () => {
    const [step, setStep] = useState(1);
    const [address, setAddress] = useState('');
    const [workType, setWorkType] = useState('');
  
    const handleNextStep = (data) => {
      if (step === 1) {
        setAddress(data);
      } else if (step === 2) {
        setWorkType(data);
      }
      setStep(step + 1);
    };
  
    const handlePreviousStep = () => {
      setStep(step - 1);
    };
  
    const handleFormSubmit = (contactInfo) => {
      console.log('Devis Submitted:', {
        address,
        workType,
        ...contactInfo,
      });
      alert('Devis envoyé avec succès!');
    };
  
    return (
      <main className="devis-form">
        {step === 1 && <LocationStep onNext={handleNextStep} />}
        {step === 2 && <WorkTypeStep onNext={handleNextStep} onPrevious={handlePreviousStep} address={address} />}
        {step === 3 && <ContactInfoStep onSubmit={handleFormSubmit} onPrevious={handlePreviousStep} address={address} workType={workType} />}
      </main>
    );
  };
  
  export default DevisForm;