import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Contact from './components/Contact';
import DevisForm from './components/DevisForm';
import ServiceDetail from './components/ServiceDetail';
import MapSection from './components/MapSection';
import Avis from './components/Avis';
import ScrollToTop from './components/ScrollToTop';  // <-- Importer ScrollToTop

function App() {

  const plumbingHeatingImages = [
    {
      src: require(`./images/plumbing/plumbing1.PNG`),
      thumbnail: require(`./images/plumbing/plumbing1.PNG`),
      title: 'collecteur de plomberie',
      description: 'Installation d\'un collecteur de plomberie centralisé pour assurer une distribution efficace et optimisée de l\'eau chaude et froide à travers toute la maison.',
    },
    {
      src: require(`./images/plumbing/plumbing2.png`),
      thumbnail: require(`./images/plumbing/plumbing2.png`),
      title: 'toilette',
      description: 'Installation de WC suspendu avec bâti-support encastré'
    },
    {
      src: require(`./images/plumbing/plumbing3.png`),
      thumbnail: require(`./images/plumbing/plumbing3.png`),
      title: 'plomberie maison',
      description: 'Installation du réseau de plombérie dans une maison neuve.'
    },
    {
      src: require(`./images/plumbing/plumbing4.png`),
      thumbnail: require(`./images/plumbing/plumbing4.png`),
      title: 'Installation d\'une salle de bain complète',
      description: 'Installation d\'une salle de bain complète.'
    }
  ];

  const pipeCleaningImages = [
    {
      src: require(`./images/pipecleaning/pipe1.png`),
      thumbnail: require(`./images/pipecleaning/pipe1.png`),
      title: 'Débouchage',
      description: 'Débouchage et analyse vidéo de canalisations.'
    }
  ];
  
  const electricityImages = [
    {
      src: require(`./images/electricity/electricity1.png`),
      thumbnail: require(`./images/electricity/electricity1.png`),
      title: 'Installation électrique',
      description: 'Installation de luminaires extérieurs sur facade et dans une terrasse.'
    },
    {
      src: require(`./images/electricity/electricity2.png`),
      thumbnail: require(`./images/electricity/electricity2.png`),
      title: 'Mise aux normes électriques',
      description: 'Installation d\'un tableau électrique et raccordement complet dans une maison.'
    },
    {
      src: require(`./images/electricity/electricity3.png`),
      thumbnail: require(`./images/electricity/electricity3.png`),
      title: 'Réparation de panne électrique',
      description: 'Installation du réseau électrique d\'une maison neuve.'
    }
  ];
  
  const multiServicesImages = [
    {
      src: require(`./images/multiservices/multiservices1.png`),
      thumbnail: require(`./images/multiservices/multiservices1.png`),
      title: 'Sol',
      description: 'Pose d\'un parquet de sol.'
    },
    {
      src: require(`./images/multiservices/multiservices2.png`),
      thumbnail: require(`./images/multiservices/multiservices2.png`),
      title: 'Tout',
      description: 'Pose de placo-plâtre et d\'une isolation pour étage de maison.'
    }
  ];

  const plumbingHeatingDescription = 'Entreprise spécialisée en travaux de plomberie pour les projets neufs et de rénovation. <p> Nous vous assisterons dans la réalisation de vos projets d’installation de sanitaire et de chauffage. </p> ';
  const pipeDescription = '<p> Notre équipe est également spécialisée dans le débouchage de canalisations.</p> Nous proposons une inspection vidéo pour détecter et résoudre de manière efficace les problèmes de canalisation. <p> Faites appel à nous pour bénéficier d\'un service professionnel et rapide ! </p> ';
  const electricityDescription = 'Que vous ayez besoin d\'une installation électrique neuve ou de rénovation, notre entreprise est là pour vous accompagner dans tous vos projets électriques. <p> Nous nous engageons à vous fournir un service de qualité, sécurisé et conforme aux normes actuelles. </p> ';
  const multiServicesDescription = 'Notre entreprise est à votre disposition pour vos travaux divers. <p> Entretien extérieur, petits travaux en tout genre, demandez et nous répondrons à vos besoins. </p> ';
  return (
    <Router>
      <ScrollToTop /> {/* Ajout de ScrollToTop ici */}
      <div className="App">
        <Header />
        <main className="content">
          <Routes>
            <Route path="/" element={
              <>
                <Hero />
                <Services />
                <Contact />
                <MapSection />
              </>
            } />
            <Route path="/demander-un-devis" element={<DevisForm />} />
            <Route path="/services/plomberie" element={<ServiceDetail title="Plomberie et de chauffage" images={plumbingHeatingImages} text={plumbingHeatingDescription} />} />
            <Route path="/services/debouchagecanalisation" element={<ServiceDetail title="Débouchage de canalisation" images={pipeCleaningImages} text={pipeDescription} />} />
            <Route path="/services/electricite" element={<ServiceDetail title="Projets électriques" images={electricityImages} text={electricityDescription} />} />
            <Route path="/services/multi-services" element={<ServiceDetail title="Multi-services" images={multiServicesImages} text={multiServicesDescription} />} />
            <Route path="/avis" element={<Avis />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
