import React, { useState } from 'react';

const WorkTypeStep = ({ onNext, onPrevious, address }) => {
  const [selectedWork, setSelectedWork] = useState('');

  const handleNext = () => {
    if (selectedWork) {
      onNext(selectedWork);
    }
  };

  return (
    <div className="step">
      <h2>De quels travaux avez-vous besoin ?</h2>
      <p>{address}</p>
      <div className="work-type-options">
        {['Plomberie/Chauffage', 'Électricité', 'Débouchages canalisations', 'Multi-services'].map(work => (
          <button 
            key={work} 
            onClick={() => setSelectedWork(work)} 
            className={selectedWork === work ? 'selected' : ''}
          >
            {work}
          </button>
        ))}
      </div>
      <div className="step-navigation">
        <button onClick={onPrevious}>Retour</button>
        <button onClick={handleNext} disabled={!selectedWork}>Suivant</button>
      </div>
    </div>
  );
};

export default WorkTypeStep;
