import React from 'react';
import './ServiceDetail.css';
import CustomCarousel from './CustomCarousel';

const ServiceDetail = ({ title, images, text }) => {
  return (
    <div className="service-detail">

      <div className="savoir-faire">
        <h1>NOTRE SAVOIR-FAIRE</h1>
        <h3>Nos services de {title.toLowerCase()} par Deshaienergies</h3>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>

      <div className="projects-container">
        <h2 className="projects-title">Nos projets réalisés</h2>
        <CustomCarousel images={images} />
      </div>
    </div>
  );
};


export default ServiceDetail;
