import React, { useState } from 'react';

const LocationStep = ({ onNext }) => {
  const [address, setAddress] = useState('');

  const handleNext = () => {
    if (address) {
      onNext(address);
    }
  };

  return (
    <div className="step">
      <h2>C'est parti ! Quelle est votre adresse ?</h2>
      <div className="input-group">
        <label>Adresse</label>
        <input 
          type="text" 
          placeholder="Adresse du chantier *" 
          value={address} 
          onChange={(e) => setAddress(e.target.value)} 
          required 
        />
      </div>
      <div className="step-navigation">
        <button onClick={handleNext} disabled={!address}>Suivant</button>
      </div>
    </div>
  );
};

export default LocationStep;
