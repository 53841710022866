import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
    setLoading(true);
    setStatus('');

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);

    fetch('https://deshaienergies.fr/contact_mail.php', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.text())
      .then(result => {
        setLoading(false);
        if (result === 'success') {
          setStatus('Message envoyé avec succès.');
        } else {
          setStatus('Une erreur est survenue. Veuillez réessayer.');
        }
      })
      .catch(() => {
        setLoading(false);
        setStatus('Une erreur est survenue. Veuillez réessayer.');
      });
  };

  return (
    <section id="contact" className="contact">
      <h2>Contactez-nous</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nom"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          placeholder="Votre message"
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <button type="submit" disabled={loading}>
          {loading ? 'Envoi en cours...' : 'Envoyer'}
        </button>
        {status && <p>{status}</p>}
      </form>
    </section>
  );
};

export default Contact;
