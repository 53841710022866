import React, { useState } from 'react';

const ContactInfoStep = ({ onPrevious, address, workType }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [details, setDetails] = useState('');
  const [telephone, setTelephone] = useState(''); // Ajout du champ téléphone
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const generateDevisNumber = () => {
    const randomNumber = Math.floor(Math.random() * 1000000);
    return `DEVIS${randomNumber.toString().padStart(4, '0')}`;
  };

  const handleSubmit = () => {
    if (name && email && telephone) { // Vérifier que le numéro de téléphone est bien saisi
      setLoading(true);
      const numeroDevis = generateDevisNumber();

      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('address', address);
      formData.append('workType', workType);
      formData.append('details', details);
      formData.append('telephone', telephone); // Envoi du numéro de téléphone
      formData.append('numeroDevis', numeroDevis);

      fetch('https://www.deshaienergies.fr/send_mail.php', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.text())
        .then(result => {
          setLoading(false);
          if (result === 'success') {
            setMessage(`Demande de devis envoyée avec succès!`);
          } else {
            setMessage('Une erreur s\'est produite. Veuillez réessayer.');
          }
        })
        .catch(() => {
          setLoading(false);
          setMessage('Une erreur s\'est produite. Veuillez réessayer.');
        });
    }
  };

  return (
    <div className="step">
      <h2>Entrez vos informations de contact</h2>
      <p>{address} - {workType}</p>
      <div className="input-group">
        <label>Nom</label>
        <input 
          type="text" 
          placeholder="Votre nom" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          required 
        />
      </div>
      <div className="input-group">
        <label>Email</label>
        <input 
          type="email" 
          placeholder="Votre email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
      </div>
      <div className="input-group">
        <label>Téléphone</label>
        <input 
          type="text" 
          placeholder="Votre numéro de téléphone" 
          value={telephone} 
          onChange={(e) => setTelephone(e.target.value)} 
          required 
        />
      </div>
      <div className="input-group">
        <label>Détails supplémentaires</label>
        <textarea 
          placeholder="Détails sur votre demande" 
          value={details} 
          onChange={(e) => setDetails(e.target.value)} 
        />
      </div>
      <div className="step-navigation">
        <button onClick={onPrevious}>Retour</button>
        <button onClick={handleSubmit} disabled={loading || !name || !email || !telephone}>
          {loading ? 'Envoi en cours...' : 'Envoyer'}
        </button>
      </div>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ContactInfoStep;
